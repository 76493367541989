<template>
    <h2>
        What is the Server IP for {{ server.title }}?
    </h2>
    <p>
        The IP for the {{ server.title }} is:
        <span class="bold ip">{{
                server.ip + (server.port !== 25565 ? ":" + server.port : "")
            }}</span>
    </p>
    <h2>
        What Game modes can I play on {{ server.title }}?
    </h2>
    <p>
        On {{ server.title }} you can enjoy the following game modes:
    </p>
    <div class="tags">
        <NuxtLink
                v-for="tag in tags"
                :key="tag.name"
                :href="'/server-tags/' + tag.urlname"
        >
            {{ tag.name }}
        </NuxtLink>
    </div>
    <h2>
        What versions does the {{ server.title }} server support?
    </h2>
    <p>
        {{ server.title }} currently supports versions: {{
            server.status && server.status.version
                    ? server.status.version.name
                    : "?"
        }}
    </p>
</template>

<script setup>
const props = defineProps({
    server: {
        type: Object,
        required: true,
    },
    tags: {
        type: Array,
        required: true,
    }
});
</script>

<style scoped lang="scss">
h2 {
  font-weight: 600;
  margin-bottom: 0.5em;

  position: relative;
  border-bottom: 1px solid #ccc;

  font-size: 0.9rem;
}

p {
  font-weight: 400;
  font-size: 0.8rem;
}

.bold {
  font-weight: 600;
}

.version {
  font-weight: 400;
}

.tags {
  display: flex;
  gap: 0.5em;
  justify-content: flex-start;
  max-width: 50%;
  flex-wrap: wrap;
  max-height: none;
  margin: 1em 0 0;

  a {
    text-decoration: none;
    color: black;
    background: #e2e8f0;
    padding: 0.25em 0.5em;
    font-size: 0.8rem;

    &:hover,
    &:focus {
      background: #cbd5e0;
    }
  }
}
</style>
