<template>
	<div>
		<Popup title="Vote">
			<form>
				<FormGroup
						v-model:parent_ref="username"
						label="Username"
						autocomplete="username"
						:check="(value) => {
                            return value.length >= fieldLimits.username.min && value.length <= fieldLimits.username.max;
                        }"
				/>
				<VueRecaptcha
						ref="recaptchaRef"
						sitekey="6LfKIb0nAAAAAHg56Kp0qwjb3qMXNiqOa7P2gPyI"
						@verify="recaptchaSubmit"
						@error="recaptchaError"
						@expired="recaptchaExpired"
				/>
				<div class="form-group submit-group">
					<button type="submit" @click.prevent="vote">
						Vote
						<img alt="Login" src="~/assets/icons/right_arrow.svg" width="14" height="14">
					</button>
				</div>
			</form>
		</Popup>
	</div>
</template>

<script setup>
import { useUser } from "~/composables/useUser.js";
import { notify } from "@kyvg/vue3-notification";
import { VueRecaptcha } from "vue-recaptcha";

const nuxtApp = useNuxtApp();

const recaptchaComponent = nuxtApp.vueApp._context.components.VueRecaptcha;

if (recaptchaComponent) {
	nuxtApp.vueApp.component("VueRecaptcha", VueRecaptcha);
}

const { user } = useUser();

let localStorageUsername = null
try {
	localStorageUsername = localStorage.getItem("vote-username");
} catch (e) {
}

const username = ref(
	user.value ? user.value.username : localStorageUsername ? localStorageUsername : ""
);

const recaptchaRef = ref(null);
const recaptchaResponse = ref(null);

const { setPopup } = usePopup();

async function vote() {
	if (!recaptchaRef.value || !recaptchaResponse.value) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	localStorage.setItem("vote-username", username.value);

	notify({
		type: "info",
		title: "Voting..."
	})

	const server_id = useRoute().params.id.split("-")[1];

	const response = await $fetch("/api/server/" + server_id + "/vote", {
		method: "POST",
		body: {
			username: username.value,
			captcha: recaptchaResponse.value
		},
		ignoreResponseError: true
	});

	let closePopup = true;

	if (response.status) {
		notify({
			type: "success",
			title: "You have voted for this server!"
		})
	} else if (response.error) {
		if (response.error === "You have already voted for this server!") {
			const date = new Date();
			date.setUTCHours(0, 0, 0, 0);
			const localTimeString = new Intl.DateTimeFormat(undefined, {
				hour: "numeric",
				minute: "numeric"
			}).format(date);

			response.error += " You can vote again at " + localTimeString + "!";
		}

		notify({
			type: "error",
			title: response.error
		})
		if (response.error === "Please complete the captcha!") {
			closePopup = false;
		}
	} else {
		notify({
			type: "error",
			title: "Error occurred while voting!"
		})
	}

	if (closePopup) {
		setPopup(null);
	}

	if (recaptchaRef.value) {
		recaptchaRef.value.reset();
	}
}

function recaptchaSubmit(token) {
	if (!recaptchaResponse) {
		return;
	}
	recaptchaResponse.value = token;
}

function recaptchaExpired() {
	notify({
		type: "error",
		title: "Captcha expired! Please try again!"
	})

	if(!recaptchaResponse || !recaptchaRef) {
		return;
	}
	recaptchaResponse.value = null;
	recaptchaRef.value.reset();
}

function recaptchaError() {
	notify({
		type: "error",
		title: "Please complete the captcha!"
	})

	if(!recaptchaResponse || !recaptchaRef) {
		return;
	}
	recaptchaResponse.value = null;
	recaptchaRef.value.reset();
}
</script>